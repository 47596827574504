import React, { useState, useLayoutEffect } from "react"
import MaterialTable from "material-table"
import { createMuiTheme, ThemeProvider, Box } from "@material-ui/core"
import { Container, Observacao } from "./styles"
import Axios from "axios"
import Idioma from "../../Language/language"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00b1b1",
    },
  },
  overrides: {
    MuiTable: {
      root: {
        minWidth: "375px",
      },
    },
    MuiTableCell: {
      root: {
        fontSize: "1.6rem",
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1rem",
      },
    },
    MuiTypography: {
      caption: {
        fontSize: "1rem",
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
      },
    },
  },
})

export default function TableCotacoes() {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useLayoutEffect(() => {
    setIsLoading(true)
    Axios.get("https://d2u011wx6mngmf.cloudfront.net/cotacao.json")
      .then(res => res.data.dados)
      .then(setData)
      .catch(console.log)
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  var note = [`<b>Observação:</b> A taxa de câmbio é indicativa, pois o valor exato
  é específico da data e hora em que a Mastercard e a Visa processam a
  transação, podendo ser diferentes da data e hora de realização da
  transação. Se sua transação for convertida pelo estabelecimento ou
  pelo operador do caixa eletrônico, as taxas de câmbio não serão
  aplicadas. Isso geralmente ocorre quando você seleciona para pagar
  na moeda do seu cartão ao invés da moeda do estabelecimento ou caixa
  eletrônico.`,

  `<b>Note:</b> The exchange rate is indicative, as the exact amount
  is specific for data and time when Mastercard and Visa cards process the transaction,
  and may differ from the date and time of the transaction.
  If your transaction is converted by the merchant or ATM operator, exchange rates will not apply.
  This usually occurs when you select to pay in the currency of your card rather than the currency of the merchant or ATM.`,

  `<b>Nota:</b> El tipo de cambio es indicativo, ya que el monto exacto es específico para la fecha y hora en que Mastercard y Visa procesan la transacción, y pueden diferir de la fecha y hora de la transacción. Si su transacción es convertida por el comerciante o el operador de cajero automático, no se aplicarán las tasas de cambio. Esto generalmente ocurre cuando selecciona pagar en la moneda de su tarjeta en lugar de la moneda del comerciante o cajero automático.`]

  var table = {
    "empty": ["Não encontramos nada", "Nothing found", "No encontramos nada"],
    "search": ["Filtrar por data, cotação ou bandeira", "Filter by date, quote or brand", "Filtrar por fecha, cotización o bandera"],
    "rows": ["Linhas por página", "Rows per page", "Lineas por pagina"],
    "date": ["Data", "Date", "Fecha"],
    "currency": ["Moeda", "Currency", "Moneda"],
    "quotation": ["Valor de cotação", "Quotation value", "Valor de cotización"],
    "brand": ["Bandeira", "Brand", "Bandera"]
  }
  return (
    <ThemeProvider theme={theme}>
      <Container>
        {data && (
          <MaterialTable
            isLoading={isLoading}
            localization={{
              body: {
                emptyDataSourceMessage: table.empty[Idioma()],
                filterRow: {
                  filterTooltip: "Filtrar",
                },
              },
              toolbar: {
                searchPlaceholder: table.search[Idioma()],
              },
              pagination: {
                nextTooltip: "Próxima",
                previousTooltip: "Anterior",
                lastTooltip: "Ir para ultima página",
                firstTooltip: "Voltar à primeira página",
                labelRowsSelect: table.rows[Idioma()],
              },
            }}
            options={{
              showTitle: false,
              sorting: false,
              pageSizeOptions: [5, 10],
              paginationType: "stepped",
            }}
            data={data}
            columns={[
              { title: table.date[Idioma()], field: "data" },
              { title: table.currency[Idioma()], field: "moeda" },
              { title: table.quotation[Idioma()], field: "cotacao" },
              {
                title: table.brand[Idioma()],
                field: "bandeira",
                render: rowData => (
                  <img
                    src={require(`../../../images/svg/${rowData.bandeira}.svg`)}
                    style={{ width: 35 }}
                  />
                ),
              },
            ]}
          />
        )}
        <Box display="flex" margin={6}>
          <Observacao dangerouslySetInnerHTML={{
                __html:note[Idioma()]}} />
        </Box>
      </Container>
    </ThemeProvider>
  )
}

function randonData() {
  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }
  const data = []
  for (let i = 0; i <= 180; i++) {
    data.push({
      data: new Date(new Date().addDays(i)).toLocaleDateString("pt-BR"),
      moeda: "1 US$",
      cotacao: `R$ ${(Math.random() * 5 + 1).toFixed(4)}`,
      bandeira: i % 2 ? "mastercard" : "visa",
    })
  }

  return data
}
