import React, { useState } from "react"
import { Container, Header, PT, EN, ES } from "./styles"
import LogoHubfintechBranco from "../../images/svg/Logo-hubfintech-branco.svg"
import { Link } from "gatsby"
import Menu from "../Menu"
import Idioma from '../Language/language'
import flagBra from "../../images/static/bra-flag.png"
import flagUsa from "../../images/static/usa-flag.png"
import flagEsp from "../../images/static/esp-flag.png"
export default function HeaderCotacoes() {

  var title = ["Taxas de Conversão <hr />", "Conversion Rates <hr />", "Tasas de Conversión <hr />"]

  const [setIndex] = useState(0);

  var select = (e) =>{
    setIndex(Idioma(e.target.id))
  }

  return (
    <Container>
      <Header>
        <Link to="/">
          <img src={LogoHubfintechBranco} alt="Hubfintech somos hub" />
        </Link>
        <h2 dangerouslySetInnerHTML={{
                __html:title[Idioma()]}} />
      </Header>
      <Menu scrollToForm={() => console.log("to form")} />
    </Container>
  )
}
