import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import HeaderCotacaoes from "../components/Cotacoes"
import Footer from "../components/Footer"
import TableCotacoes from "../components/Cotacoes/Table"
export default function Cotacao() {
  return (
    <Layout>
      <SEO title="Cotações" />
      <HeaderCotacaoes />
      <TableCotacoes />
      <Footer />
    </Layout>
  )
}
