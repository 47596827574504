import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;

  .MuiPaper-root {
    width: 70% !important;
  }
  ${media.lessThan("600px")`
    .MuiPaper-root{
      width: 100% !important;
    }
  `}
`

export const Observacao = styled.p`
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.65px;
`
